import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Input, Table, Card, Select, Tag } from "antd";

import dayjs from "dayjs";
import axios from "axios";

import { CloudDownloadOutlined, SearchOutlined } from "@ant-design/icons";
import exportToCSV from "../Ticket/helper/ExportToCSV";

const TransactionTable = ({ tableData, loading }) => {
  const [leadOwners, setLeadOwners] = useState([]);
  const [plans, setPlans] = useState([]);
  const [search, setSearch] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [subscription, setSubscription] = useState("");
  const [type, setType] = useState("");

  const filterData = useMemo(() => {
    let data = tableData;
    if (search) {
      data = data.filter((item) =>
        item?.lab?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.contact?.toString().toLowerCase().includes(search?.toLowerCase())
      );
    }
    if (salesPerson) {
      data = data.filter((item) => item.leadOwner === salesPerson);
    }
    if (subscription) {
      data = data.filter((item) => item.plan === subscription);
    }
    if (type) {
      data = data.filter((item) => item.type === type);
    }
    return data;
  }, [search, salesPerson, subscription, type, tableData]);

  useEffect(() => {
    fetchLeadOwners();
    fetchPlansData();
  }, []);

  const fetchLeadOwners = async () => {
    axios.get("/admin-dashboard/leadOwner").then((res) => {
      const options = res?.data?.map((plan) => ({
        value: plan,
        label: plan,
      }));
      setLeadOwners(options);
    });
  };

  const fetchPlansData = async () => {
    axios.get("/admin-dashboard/plans").then((res) => {
      const options = res?.data?.map((plan) => ({
        planId: plan._id,
        value: plan.name,
        label: plan.name,
      }));
      setPlans(options);
    });
  };

  const exportData = filterData?.map((data) => {
    return {
      "Date&Time": `${dayjs(data.createdAt).format("DD-MM-YYYY hh:mm A")}`,
      "Lab Name": `${data?.lab}`,
      "Contact": `${data?.countryCode} ${data?.contact}`,
      "Sales Person": data?.leadOwner,
      Subscription: data?.plan,
      Amount: data?.amount,
      type: data?.type,
    };
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      width: "5%",
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "10%",
      render: (value) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <div>{dayjs(value).format("DD-MM-YYYY")}</div>
          <div>{dayjs(value).format("hh:mm A")}</div>
        </div>
      ),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Lab Name",
      dataIndex: "lab",
      key: "lab",
      width: "45%",
      render: (value, record) => <Flex vertical gap={3}>
        <span>{value}</span>
        <span>+ {record?.countryCode} {record?.contact}</span>
      </Flex>,
    },
    {
      title: "Sales Person",
      dataIndex: "leadOwner",
      key: "salesPerson",
      width: "15%",
    },
    {
      title: "Subscription",
      dataIndex: "plan",
      key: "plan",
      width: "10%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      render: (value) => <span>₹{value}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "lab",
      width: "10%",
      render: (value) => (
        <Tag color={value === "Renew" ? "yellow" : value === "New" ? "cyan" : ""}>{value}</Tag>
      ),
    },
  ];

  return (
    <>
      <Card
        bodyStyle={{
          padding: "20px 0",
          paddingBottom: 0,
        }}
        style={{
          border: "1px solid  #D9D9D9",
          borderRadius: "4px",
        }}
      >
        <Flex
          justify="space-between"
          style={{
            padding: "0 20px",
          }}
        >
          <Flex gap={13}>
            <Input
              prefix={<SearchOutlined style={{ color: "#8d8d8d" }} />}
              placeholder="Search Lab by Name and Contact"
              style={{
                width: 290,
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              placeholder="Subscription"
              style={{
                width: 160,
              }}
              allowClear
              options={plans}
              onChange={(value) => setSubscription(value)}
            />
            <Select
              placeholder="Sales Person"
              style={{
                width: 160,
              }}
              allowClear
              options={leadOwners}
              onChange={(value) => setSalesPerson(value)}
            />
            <Select
              placeholder="Type"
              style={{
                width: 160,
              }}
              allowClear
              options={[
                { label: "Renew", value: "Renew" },
                { label: "New", value: "New" },
                { label: "Others", value: "Others" },
              ]}
              onChange={(value) => setType(value)}
            />
          </Flex>
          <Button
            icon={<CloudDownloadOutlined />}
            popupClassName="popup__menu"
            onClick={() => exportToCSV(exportData, "TransactionDetails")}
          >
            Export
          </Button>
        </Flex>
        <Table
          dataSource={filterData}
          columns={columns}
          style={{
            marginTop: 20,
            borderBottom: "none",
          }}
          rootClassName={`custom-table`}
          className={`custom-table`}
          tableLayout={{
            Headers: {
              borderRadius: 0,
            },
          }}
          pagination={false}
          loading={loading}
        />
      </Card>
    </>
  );
};

export default TransactionTable;

import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Modal,
  Divider,
  Upload,
  message,
  Input,
  Select,
  Flex,
  Checkbox,
} from "antd";
import {
  UploadOutlined,
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Header from "../Base/Header";
import styles from "./Blogs.module.css";
import BackButton from "../../asssets/back-btn.svg";
import { Link } from "react-router-dom";
import MarkdownEditor from "@uiw/react-markdown-editor";
import axios from "axios";
import AddBanner from "./AddBanner";

const AddBlog = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState();
  const [markdown, setMarkdown] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [urlReport, setUrlReport] = useState(null);
  const [checked, setChecked] = useState(false);
  const [downloadLink, setDownloadLink] = useState({
    uid: '-1',
    name: '',
    status: '',
    url: '',
    thumbUrl: '',
  });
  const navigate = useNavigate();
  const inputRef = useRef();

  useEffect(() => {
    fetchCategories();
  }, []);

  // Markdown default light theme
  document.documentElement.setAttribute("data-color-mode", "light");

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`/blog-category/all`);
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryFromSlug = (slug) => {
    const data = categories.find((item) => item.slug === slug);
    return {
      _id: data._id,
      title: data.title,
      slug: data.slug,
    };
  };

  const categoryOptions = categories?.map((item) => {
    return {
      title: item?.title,
      value: item?.slug,
    };
  });

  const handleAddBlog = (values) => {
    try {
      const newBlog = {
        ...values,
        downloadLink: downloadLink?.url,
        category: getCategoryFromSlug(values.category),
        content: markdown,
      };
      axios.post("/blog-post/add", newBlog);
      message.success("Blog Added!");
      navigate("/blogs");
    } catch (error) {
      console.log(error);
    }
  };

  // UPLOAD FILE
  const handleUploadFile = async (file) => {
    const fileType = file?.name?.split(".")?.pop();
    const url = await axios.get(
      "/s3Url?fileType=" + fileType + "&iswebasset=true",
    );
    const { data } = url;
    await fetch(data.url, {
      method: "PUT",
      headers: {
        "Content-Type": file?.type,
      },
      body: file,
    });
    const imageUrl =
      "https://cdn.flabs.in/webassets/" + data.name + "." + fileType;
    setUrl(imageUrl);
    setUrlReport(
      "https://flabs-prod.s3.ap-south-1.amazonaws.com/webassets/" +
      data.name +
      "." +
      fileType,
    );
  };

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      document.getSelection().removeAllRanges();
      message.success("Copied to clipboard");
    }
  };

  const handleCopy1 = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      document.getSelection().removeAllRanges();
      message.success("Copied to clipboard");
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isPNG) {
        setUploadedFile(null);
        message.error(`${file.name} is not an image`);
      } else {
        setUploadedFile(file);
      }
      return false;
    },
    onRemove: () => {
      setUploadedFile(null);
      setUrl(false);
    },
    maxCount: 1,
  };

  return (
    <>
      <Header />
      <section className={styles["blog-section-container"]}>
        <div className={styles["blog-header-container"]}>
          <Link className={styles["add-blog-back-btn"]} to="/blogs">
            <img src={BackButton} alt="back-btn" />
            Back
          </Link>
          <Button
            style={{
              border: "1px solid #4678EE",
              color: "#4678EE",
            }}
            onClick={() => setIsModalOpen(true)}
          >
            <UploadOutlined />
            Upload Image
          </Button>
        </div>
        <Form
          onFinish={handleAddBlog}
          layout="vertical"
          initialValues={{
            contact_us_popup: true,
          }}
        >
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Title"
              name="title"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Time for Read"
              name="time_for_read"
              className={styles["add-blog-form-item"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Keywords"
              name="keywords"
              className={styles["add-blog-form-item"]}
            >
              <Input.TextArea autoSize={{ minRows: 2 }} />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Category"
              name="category"
              className={styles["add-blog-form-item"]}
            >
              <Select allowClear options={categoryOptions} />
            </Form.Item>
            <Form.Item
              label="Short Description"
              name="short_description"
              className={styles["add-blog-form-item"]}
            >
              <Input.TextArea autoSize={{ minRows: 2 }} />
            </Form.Item>
          </div>
          <div className={styles["add-blog-form-row"]}>
            <Form.Item
              label="Thumbnail"
              name="thumbnail"
              className={styles["add-blog-form-item"]}
              style={{
                marginBottom: "30px",
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Us Popup"
              name="contact_us_popup"
              className={styles["add-blog-form-item"]}
              valuePropName="checked"
            >
              <Checkbox>Contact Us Popup</Checkbox>
            </Form.Item>
          </div>
          <div
            style={{
              maxWidth: "100%",
            }}
          >
            <MarkdownEditor
              value={markdown}
              enableScroll={true}
              overflow={true}
              height="300px"
              visible={true}
              onChange={(value, viewUpdate) => setMarkdown(value)}
            />
          </div>
          <AddBanner checked={checked} setChecked={setChecked} downloadLink={downloadLink} setDownloadLink={setDownloadLink} />
          <p
            style={{
              marginTop: "40px",
              marginBottom: "30px",
              color: "#707070",
              fontSize: "14px",
            }}
          >
            FAQs
          </p>
          <Form.List name="faq">
            {(fields, { add, remove }) => (
              <div className={styles["add-faq"]}>
                {fields.map(({ key, name, ...restField }) => (
                  <Flex
                    key={key}
                    align="center"
                    gap={20}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      className={styles["add-blog-form-item"]}
                      {...restField}
                      name={[name, "question"]}
                      rules={[{ required: true, message: "Missing Question" }]}
                    >
                      <Input.TextArea
                        placeholder={`Question`}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                    <Form.Item
                      className={styles["add-blog-form-item"]}
                      {...restField}
                      name={[name, "answer"]}
                      rules={[{ required: true, message: "Missing Answer" }]}
                    >
                      <Input.TextArea
                        placeholder={`Answer`}
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{
                        color: "red",
                        fontSize: "16px",
                      }}
                    />
                  </Flex>
                ))}
                <Button
                  type="dashed"
                  style={{
                    width: "500px",
                  }}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add FAQs
                </Button>
              </div>
            )}
          </Form.List>
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </section>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={<span style={{ fontWeight: "400" }}>Upload Image to CDN</span>}
        footer={null}
      >
        <Divider />
        <Upload.Dragger
          {...uploadProps}
          style={{
            marginBottom: "12px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
        <Divider />
        {!url ? (
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (uploadedFile) {
                  handleUploadFile(uploadedFile);
                } else {
                  message.warning("Please upload image first!");
                }
              }}
            >
              Upload
            </Button>
          </div>
        ) : (
          <div>
            <Flex justify="space-between" gap={8}>
              <Input ref={inputRef} readOnly={true} value={url} />
              <Button type="primary" onClick={handleCopy}>
                Copy URL
              </Button>
            </Flex>
            <Flex
              justify="space-between"
              gap={8}
              style={{
                marginTop: "12px",
              }}
            >
              <Input ref={inputRef} readOnly={true} value={urlReport} />
              <Button type="primary" onClick={handleCopy1}>
                Copy URL for Report
              </Button>
            </Flex>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddBlog;

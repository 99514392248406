import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  message,
  Radio,
  Upload,
} from "antd";
import axios from "axios";
import React, { useState } from "react";

const { TextArea } = Input;



const AddBanner = ({ checked, setChecked, downloadLink, setDownloadLink }) => {
  const [url, setUrl] = useState("");
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/PDF file!');
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    return true;
  };
  const handleUploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      const response = await axios.get("/s3Url", {
        params: { fileType: "pdf", iswebasset: true },
      });
      const { data } = response;
      const uploadResponse = await fetch(data.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": "application/pdf"
        },
      });

      if (uploadResponse.status === 200) {
        const imageUrl = `https://cdn.flabs.in/webassets/${data.name}.pdf`;
        setUrl(imageUrl);
        setDownloadLink({
          ...downloadLink,
          url: imageUrl,
          name: "Document",
          thumbUrl: imageUrl,
        });
        onSuccess({ url: imageUrl }, file);
        message.success(`Image uploaded successfully`);
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error("Upload Error:", error);
      message.error(`Upload failed: ${error.message}`);
      onError(error);
    }
  };
  return (
    <div style={{ marginTop: 10 }}>
      <Form.Item name="isBanner" valuePropName="checked">
        <Checkbox
          style={{ margin: "15px 0" }}
          onChange={(e) => setChecked(e.target.checked)}
        >
          Banner
        </Checkbox>
      </Form.Item>
      {checked && (
        <>
          <Flex style={{ width: "100%" }}>
            <Card
              title="Desktop"
              size="small"
              style={{
                width: "100%",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderColor: "#d9d9d9",
                borderBottomLeftRadius: 0,
                borderRight: "none",
              }}
              bodyStyle={{
                borderTopLeftRadius: 0,
              }}
              actions={[]}
            >
              <Flex vertical gap={8}>
                <Form.Item label="Content Line 1" name="desktopContactLine1">
                  <TextArea showCount maxLength={50} />
                </Form.Item>
                <Form.Item label="Content Line 2" name="desktopContactLine2">
                  <TextArea showCount maxLength={50} />
                </Form.Item>
              </Flex>
            </Card>
            <Card
              title="Mobile"
              size="small"
              style={{
                width: "100%",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderColor: "#d9d9d9",
              }}
            >
              <Form.Item label="Content" name="mobileContactLine1">
                <TextArea />
              </Form.Item>
            </Card>
          </Flex>
          <Flex
            vertical
            gap={8}
            justify="flex-start"
            align="flex-start"
            style={{
              width: "100%",
              padding: 12,
              border: "1px solid #d9d9d9",
              borderTop: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <Form.Item name="bannerType">
              <Radio.Group>
                <Radio value="download">Download</Radio>
                <Radio value="startFreeTrial">Start Free Trail</Radio>
              </Radio.Group>
            </Form.Item>
            <Flex
              vertical
              gap={3}
              justify="flex-start"
              align="flex-start"
              style={{ width: "50%" }}
            >
              <span style={{ color: "black" }}>File Upload</span>
              <Upload
                customRequest={handleUploadFile}
                listType="picture"
                fileList={downloadLink.url ? [downloadLink] : []}
                style={{ width: "300px" }}
                onRemove={() => setDownloadLink({
                  uid: '-1',
                  name: '',
                  status: '',
                  url: '',
                  thumbUrl: '',
                })}
                beforeUpload={beforeUpload}
                maxCount={1}
                accept='.pdf'
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Flex>
          </Flex>
        </>
      )}
    </div >
  );
};

export default AddBanner;

import React, { useEffect, useRef, useState } from "react";
import VirtualTable from "./VirtualTable";
import "./../style.css";
import axios from "axios";
import { connect } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";
import { Input, Select, DatePicker, message, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import LeadOwner from "./LeadOwner";
import LeadSource from "./Leadsource";
import EditLab from "./EditLab";
import MenualPaymentModal from "./MenualPaymentModal";
import SupportPerson from "./SupportPerson";
import SearchInput from "./SearchInput";
import B2bPaymentModal from "./B2bPaymentModal";
import MachineIntegrator from "./MachineIntegrator";
import Language from "./Language";

const { Search } = Input;
const { RangePicker } = DatePicker;

const leadSources = ["Groweasy", "Google ads", "Reseller Channel"];
const leadSourcesOptions = leadSources.map((source) => ({
  value: source,
  label: source,
}));

const LabInfoTable = ({ authToken, userRole, userMainRole }) => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [plans, setPlans] = useState([]);
  const [leadOwners, setLeadOwners] = useState([]);
  const [supportPerson, setSupportPerson] = useState([]);
  const [machineIntegrator, setMachineIntegrator] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedLeadOwner, setSelectedLeadOwner] = useState("");
  const [selectedSupportPerson, setSelectedSupportPerson] = useState("");
  const [selectedMachineIntegrator, setSelectedMachineIntegrator] = useState("");
  const [language, setLanguage] = useState("");
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [syncData, setSyncData] = useState(false);
  const [menualPaymentOpen, setMenualPaymentOpen] = useState(false);
  const [editLabOpen, setEditLabOpen] = useState(false);
  const [editLabId, setEditLabId] = useState(null);
  const [menualPaymentLabId, setMenualPaymentLabId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [showAllData, setShowAllData] = useState(false);
  const [isShowB2BPayment, setIsShowB2BPayment] = useState(false);
  const [b2BPaymentLabId, setB2BPaymentLabId] = useState(null);

  useEffect(() => {
    fetchLeadOwners();
    fetchPlansData();
    fetchSupportPerson();
    fetchMachineIntegrator();
    fetchLangauges();
  }, []);

  console.log(tableData);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    console.log("I am here");
    fetchLabsData(
      selectedPlan,
      dateRange,
      selectedLeadOwner,
      selectedLeadSource,
      selectedSupportPerson,
      searchQuery,
      selectedMachineIntegrator,
      language
    );
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [
    selectedPlan,
    dateRange,
    syncData,
    selectedLeadOwner,
    selectedLeadSource,
    selectedSupportPerson,
    showAllData,
    selectedMachineIntegrator,
    language
  ]);

  // useEffect(() => {
  //   // deboune for search
  //   const timeOutId = setTimeout(() => {
  //     fetchLabsData(
  //       selectedPlan,
  //       dateRange,
  //       selectedLeadOwner,
  //       selectedLeadSource,
  //       selectedSupportPerson,
  //       searchQuery,
  //     );
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeOutId);
  //   };
  // }, [searchQuery]);

  const dateFilter = async (date, dateString) => {
    // start date -> gte -> 0000 karna h
    const localDate = [
      dayjs(new Date(dateString[0]).setHours(0, 0, 0)),
      dayjs(new Date(dateString[1]).setHours(23, 59, 59)),
    ];
    setDateRange(localDate);
    console.log(date, dateString);
  };

  const isAllFilterEmpty = (
    plan,
    rangeDate,
    leadOwner,
    leadSource,
    supportPerson,
    search,
    machineIntegrator,
    language
  ) => {
    // log all the values
    console.log(
      "filterValues",
      plan,
      rangeDate,
      leadOwner,
      leadSource,
      supportPerson,
      search,
      machineIntegrator,
      language
    );
    if (showAllData) {
      return false;
    }
    if (
      plan === "" &&
      rangeDate.length === 0 &&
      leadOwner === "" &&
      leadSource === "" &&
      supportPerson === "" &&
      search === "" &&
      machineIntegrator === "" &&
      language === ""
    ) {
      setTableData([]);
      return true;
    }
  };

  const fetchLabsData = async (
    plan = "",
    rangeDate = [],
    leadOwner = "",
    leadSource = "",
    supportPerson = "",
    search = searchQuery,
    machineIntegrator = "",
    language = ""
  ) => {
    try {
      if (
        isAllFilterEmpty(
          plan,
          rangeDate,
          leadOwner,
          leadSource,
          supportPerson,
          search,
          machineIntegrator,
          language
        )
      ) {
        return;
      }
      console.log("cancelTokenSource", cancelTokenSource);
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled due to new request.");
      }
      const source = await axios.CancelToken.source();
      setCancelTokenSource(source);
      setLoading(true);
      let planId = "";
      if (plan !== "") {
        const selectedOption = plans.find((option) => option.value === plan);
        planId = selectedOption?.planId;
      }

      // const token = localStorage.getItem("token");
      console.log("token", source.token);
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
        params: {
          ...(dateRange.length === 2 && {
            lte: dateRange[1],
            gte: dateRange[0],
          }),
          ...(planId !== "" && { plan: planId }),
          isSync: syncData,
          ...(leadOwner !== "" && { leadOwner }),
          ...(leadSource !== "" && { leadSource }),
          ...(supportPerson !== "" && { supportPerson }),
          ...(search !== "" && { search }),
          ...(machineIntegrator !== "" && { machineIntegrator }),
          ...(language !== "" && { language }),
        },
      };

      const response = await axios.get("/admin-dashboard", {
        ...config,
        cancelToken: source.token,
      });
      let data = [];
      for (let i = 0; i < response.data.length; i++) {
        const startDate = new Date(response.data[i]?.subStartDate);
        const endDate = new Date();
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        data.push({
          ...response.data[i],
          freeTrialDays: diffDays || 0,
        });
      }
      setTableData(data);
      setLoading(false);
      setSyncData(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Something went wrong:", error);
      }
      // message.error("Error fetching table data", [3]);
      // setLoading(false);
    }
  };

  const fetchPlansData = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get("/admin-dashboard/plans", config);

      const options = response?.data?.map((plan) => ({
        planId: plan._id,
        value: plan.name,
        label: plan.name,
      }));

      setPlans(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  const fetchLeadOwners = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get("/admin-dashboard/leadOwner", config);

      const options = response?.data?.map((plan) => ({
        value: plan,
        label: plan,
      }));

      setLeadOwners(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  const fetchSupportPerson = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get(
        "/admin-dashboard/supportPerson",
        config,
      );

      const options = response?.data?.map((plan) => ({
        value: plan,
        label: plan,
      }));

      setSupportPerson(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  const fetchMachineIntegrator = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get(
        "/admin-dashboard/machineIntegrator",
        config,
      );

      const options = response?.data?.map((plan) => ({
        value: plan,
        label: plan,
      }));

      setMachineIntegrator(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  const fetchLangauges = async () => {
    try {
      // const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Custom ${authToken}`,
        },
      };
      const response = await axios.get(
        "/admin-dashboard/getLanguages",
        config,
      );

      const options = response?.data?.map((plan) => ({
        value: plan,
        label: plan,
      }));

      setLanguages(options);
    } catch (error) {
      message.error("Error fetching plan data", [3]);
    }
  };

  let updatedTableData = tableData?.map((item, index) => ({
    ...item,
    index: index,
  }));

  const filteredData = updatedTableData?.filter((item) => {
    return item;
    // if (searchQuery === "") return item;
    // if (item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()))
    //   return item;
    // if (
    //   item?.labAdminID?.contact
    //     ?.toString()
    //     ?.toLowerCase()
    //     ?.includes(searchQuery.toLowerCase())
    // )
    //   return item;
    // if (
    //   item?.labID
    //     ?.toString()
    //     ?.toLowerCase()
    //     ?.includes(searchQuery.toLowerCase())
    // )
    //   return item;
    // if (item?.city?.toLowerCase()?.includes(searchQuery.toLowerCase()))
    //   return item;
  });

  const loginTolab = (data) => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    axios
      .get(
        `/admin-dashboard/superAdminLogin?contact=${data?.labAdminID?.contact}`,
        config,
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.token) {
          // localStorage.setItem("labToken", res?.data?.token);
          if (process.env.REACT_APP_ENVIRONMENT === "production") {
            window.open(
              `https://labs.flabslis.com/superAdminLogin/${res?.data?.token}/${res.data.browserCode}`,
              "_blank",
            );
          } else {
            window.open(
              `https://labstaging.flabs.in/superAdminLogin/${res?.data?.token}/${res.data.browserCode}`,
              "_blank",
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loginTolabPreprod = (data) => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    axios
      .get(
        `/admin-dashboard/superAdminLogin?contact=${data?.labAdminID?.contact}`,
        config,
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.token) {
          window.open(
            `https://preprod.flabs.in/superAdminLogin/${res?.data?.token}/${res.data.browserCode}`,
            "_blank",
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loginToNewLogin = (data) => {
    const config = {
      headers: {
        Authorization: `Custom ${authToken}`,
      },
    };
    axios
      .get(
        `/admin-dashboard/superAdminLogin?contact=${data?.labAdminID?.contact}`,
        config,
      )
      .then((res) => {
        if (res?.data?.token) {
          window.open(
            `https://lab.flabslis.com/superAdminLogin/${res?.data?.token}/${res.data.browserCode}`,
            "_blank",
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyPaymentURL = (data) => {
    let url = "";
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      url = `https://labs.flabslis.com/payment/${data?._id}/true`;
    } else {
      url = `https://labstaging.flabs.in/payment/${data?._id}/true`;
    }
    navigator.clipboard.writeText(url).then(
      function () {
        message.success("URL copied to clipboard", [3]);
      },
      function (err) {
        message.error("Error copying URL", [3]);
      },
    );
  };

  const exportData = updatedTableData?.map((item) => {
    return {
      "Sr No.": item.index + 1,
      "Lab Name": item.name,
      Email: item.email,
      "Bill Counts": item.totalBills,
      "Total Reports": item.totalReports,
      Whatsapp: item.whatsapp,
      "No of Days Used": item.freeTrialDaysUsed,
      "Free Trial Days": item.freeTrialDays,
      "Subscription Plan": item.plan?.name,
      "Paid Amount": item.paidAmount,
      "Plan Duration": item.subDuration + " Months",
      "Total Income": item.totalAmount,
      "Phone Number": item.labAdminID?.contact,
      "Subscription End Date": item.subEndDate
        ? new Date(item.subEndDate).toLocaleDateString("en-GB")
        : "",
      "Number of Payments": item.number_of_payments,
      "Last Payment Date": item.lastPaymentDate,
      "Lead Owner": item.leadOwner,
      "Lead Source": item.leadSource,
      "Support Person": item.supportPerson,
    };
  });

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  let columns = [
    {
      title: "Sr No.",
      dataIndex: "key",
      width: "1",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Lab Name",
      dataIndex: "name",
      width: 100,
      render: (text, record) => {
        return (
          <div>
            <div>{text}</div>
            <div>(Lab Id: {record?.labID})</div>
            <div>City: {record?.city}</div>
          </div>
        );
      },
    },
    {
      title: "Bill Counts",
      dataIndex: "totalBills",
      width: 100,
      sorter: {
        compare: (a, b) => a.totalBills - b.totalBills,
        multiple: 3,
      },
    },
    {
      title: "Total Reports",
      dataIndex: "totalReports",
      width: 180,
      sorter: {
        compare: (a, b) => a.totalReports - b.totalReports,
        multiple: 2,
      },
    },
    {
      title: "Whatsapp",
      dataIndex: "whatsapp",
      width: 140,
      sorter: {
        compare: (a, b) => a.whatsapp - b.whatsapp,
        multiple: 4,
      },
    },
    {
      title: "Total Income",
      dataIndex: "totalAmount",
      width: 120,
      sorter: {
        compare: (a, b) => a.totalAmount - b.totalAmount,
      },
    },
    {
      title: "No of Days Used",
      dataIndex: "freeTrialDaysUsed",
      width: 120,
      sorter: {
        compare: (a, b) => a.freeTrialDaysUsed - b.freeTrialDaysUsed,
      },
    },
    {
      title: "Free Trial Days",
      dataIndex: "freeTrialDays",
      width: 120,
      sorter: {
        compare: (a, b) => a.freeTrialDays - b.freeTrialDays,
      },
    },
    {
      title: "Subscription",
      dataIndex: "plan",
      width: 200,
      render: (text, record) => {
        return (
          <div
            style={{
              width: "100px",
            }}
          >
            <div>Plan: {text?.name}</div>
            <div>
              Paid Amount:
              <br />
              {record?.paidAmount}
            </div>
            <div>
              Duration:
              <br />
              {record?.subDuration} Months
            </div>
          </div>
        );
      },
    },
    // {
    //   title: "Paid Amount",
    //   dataIndex: "paidAmount",
    //   width: 140,
    //   sorter: {
    //     compare: (a, b) => a.whatsapp - b.whatsapp,
    //     multiple: 4,
    //   },
    // },
    // {
    //   title: "Plan Duration",
    //   dataIndex: "subDuration",
    //   width: 120,
    //   render: (text) => {
    //     if (text) return `${text} Months`;
    //     return null;
    //   },
    //   sorter: {
    //     compare: (a, b) => a.subDuration - b.subDuration,
    //     multiple: 4,
    //   },
    // },
    {
      title: "Phone Number",
      dataIndex: "labAdminID",
      width: 160,
      render: (text) => {
        return text?.contact;
      },
    },
    {
      title: "Subscription End Date",
      dataIndex: "subEndDate",
      width: 200,
      sorter: {
        compare: (a, b) => new Date(a.subEndDate) - new Date(b.subEndDate),
        multiple: 1,
      },
      render: (text) => {
        if (text) return new Date(text).toLocaleDateString("en-GB");
        return null;
      },
    },
    {
      title: "Lead Owner",
      dataIndex: "leadOwner",
      width: 160,
      render: (text, record) => {
        return (
          <LeadOwner
            leadOwners={leadOwners}
            value={text}
            labId={record?._id}
            authToken={authToken}
          />
        );
      },
    },
    {
      title: "Support Person",
      dataIndex: "supportPerson",
      width: 160,
      render: (text, record) => {
        return (
          <SupportPerson
            supportPersons={supportPerson}
            value={text}
            labId={record?._id}
            authToken={authToken}
          />
        );
      },
    },
    {
      title: "Lead Source",
      dataIndex: "leadSource",
      width: 160,
      render: (text, record) => {
        return (
          <LeadSource
            leadSources={leadSourcesOptions}
            value={text}
            labId={record?._id}
            authToken={authToken}
          />
        );
      },
    },
    {
      title: "Machine Integrator",
      dataIndex: "machineIntegrator",
      width: 160,
      render: (text, record) => {
        return (
          <MachineIntegrator
            machineIntegrators={machineIntegrator}
            value={text}
            labId={record?._id}
            authToken={authToken}
          />
        );
      },
    },
    {
      title: "Language",
      dataIndex: "language",
      width: 160,
      render: (text, record) => {
        return (
          <Language
            languages={languages}
            value={text}
            labId={record?._id}
            authToken={authToken}
          />
        );
      },
    },
    {
      title: "Lab Login",
      dataIndex: "action",
      width: 300,
      render: (text, record, data) => {
        // console.log(record, text, data);
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              onClick={() => loginTolab(record)}
              style={{
                width: "100%",
              }}
            >
              Login
            </Button>
            {userRole === "admin" || (userRole === "user" && userMainRole === "product") ? (
              <Button
                type="primary"
                onClick={() => loginTolabPreprod(record)}
                style={{
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                Preprod Login
              </Button>
            ) : null}
            <Button
              type="primary"
              onClick={() => loginToNewLogin(record)}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              New Login
            </Button>
            <Button
              type="dashed"
              onClick={() => copyPaymentURL(record)}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              Payment URL
            </Button>
            {userRole === "admin" && (
              <Button
                type="dashed"
                onClick={() => {
                  setMenualPaymentOpen(!menualPaymentOpen);
                  setMenualPaymentLabId(record?._id);
                }}
                style={{
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                Manual Payment
              </Button>
            )}
            <Button
              type="dashed"
              onClick={() => {
                setEditLabOpen(!editLabOpen);
                setEditLabId(record?._id);
              }}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              Edit Lab
            </Button>
            {userRole === "admin" &&
              (
                <Button
                  type="dashed"
                  onClick={() => {
                    setIsShowB2BPayment(!isShowB2BPayment);
                    setB2BPaymentLabId(record?._id);
                  }}
                  style={{
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  B2B Payment
                </Button>
              )
            }
          </div>
        );
      },
    },
  ];

  if (!authToken) {
    return <div>Loader</div>;
  }

  const filteredColumns = columns.filter((column) => {
    const currentPlan = selectedPlan;
    console.log("currentPlan", selectedPlan);
    if (currentPlan === "Free Trial" || currentPlan === "Extended Trial") {
      // add freeTrialDaysUsed column
      if (
        column.dataIndex == "freeTrialDays" ||
        column.dataIndex == "freeTrialDaysUsed"
      ) {
        return true;
      }
    } else {
      if (
        column.dataIndex == "freeTrialDays" ||
        column.dataIndex == "freeTrialDaysUsed"
      ) {
        return false;
      }
    }
    return true;
  });

  const changeShowAllData = () => {
    if (showAllData) {
      // empty all data
      setTableData([]);
    }
    setShowAllData(!showAllData);
  };

  return (
    <div>
      <section className="lab_info_container_top">
        {userRole === "admin" && (
          <Button type="primary" onClick={() => changeShowAllData()}>
            {showAllData ? "Hide" : "Show"} All Data
          </Button>
        )}
      </section>
      <section className="lab_info_container">
        <EditLab
          editLabOpen={editLabOpen}
          setEditLabOpen={setEditLabOpen}
          plans={plans}
          labId={editLabId}
          authToken={authToken}
        />
        <MenualPaymentModal
          menualPaymentOpen={menualPaymentOpen}
          setMenualPaymentOpen={setMenualPaymentOpen}
          plans={plans}
          labId={menualPaymentLabId}
          authToken={authToken}
        />
        <B2bPaymentModal
          labId={b2BPaymentLabId}
          authToken={authToken}
          isModalOpen={isShowB2BPayment}
          setIsModalOpen={setIsShowB2BPayment}
        />
        <div className="table__container">
          <div className="table-header-container">
            <div className="search-box-container">
              <SearchInput
                fetchLabsData={fetchLabsData}
                selectedPlan={selectedPlan}
                dateRange={dateRange}
                selectedLeadOwner={selectedLeadOwner}
                selectedLeadSource={selectedLeadSource}
                selectedSupportPerson={selectedSupportPerson}
                setSearchQueryParent={setSearchQuery}
                cancelTokenSource={cancelTokenSource}
              />
            </div>
            <div className="table-header-right">
              <span>
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={() => setSyncData(true)}
                >
                  Sync Data
                </Button>
              </span>
              {userRole === "admin" && (
                <span
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <Button onClick={() => exportToCSV(exportData, "LabsData")}>
                    Export
                  </Button>
                </span>
              )}
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={leadSourcesOptions}
                  placeholder="Lead Source"
                  value={selectedLeadSource || undefined}
                  onChange={(value) => setSelectedLeadSource(value)}
                />
              </span>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={leadOwners}
                  placeholder="Lead Owner"
                  showSearch
                  value={selectedLeadOwner || undefined}
                  onChange={(value) => setSelectedLeadOwner(value)}
                />
              </span>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={supportPerson}
                  showSearch
                  placeholder="Support Person"
                  value={selectedSupportPerson || undefined}
                  onChange={(value) => setSelectedSupportPerson(value)}
                />
              </span>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={machineIntegrator}
                  showSearch
                  placeholder="Machine Integrator"
                  value={selectedMachineIntegrator || undefined}
                  onChange={(value) => setSelectedMachineIntegrator(value)}
                />
              </span>
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={languages}
                  showSearch
                  placeholder="Language"
                  value={language || undefined}
                  onChange={(value) => setLanguage(value)}
                />
              </span>
              <span
                style={{
                  marginRight: "10px",
                  width: "100%",
                }}
              >
                <Select
                  allowClear={true}
                  popupClassName="popup__menu"
                  style={{ width: 130 }}
                  options={plans}
                  placeholder="Subscription Plan"
                  value={selectedPlan || undefined}
                  onChange={(value) => setSelectedPlan(value)}
                />
              </span>
              <span>
                <RangePicker
                  value={dateRange}
                  onChange={dateFilter}
                  allowClear={false}
                  format="YYYY-MM-DD"
                  style={{ width: 200 }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().startOf("day"), dayjs().endOf("day")],
                    },
                    {
                      label: "Last 5 days",
                      value: [dayjs().add(-5, "d"), dayjs()],
                    },
                    {
                      label: "This Week",
                      value: [dayjs().startOf("week"), dayjs().endOf("week")],
                    },
                    {
                      label: "This Month",
                      value: [dayjs().startOf("month"), dayjs().endOf("month")],
                    },
                    {
                      label: "Last 30 Days",
                      value: [dayjs().add(-30, "d"), dayjs()],
                    },
                  ]}
                />
              </span>
            </div>
          </div>
          <VirtualTable
            loading={loading}
            columns={filteredColumns}
            dataSource={updatedTableData}
            scroll={
              windowWidth > 768 && {
                x: "88vw",
              }
            }
          />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    userRole: state.auth.userRole,
    userMainRole: state.auth.userMainRole,
  };
};

export default connect(mapStateToProps)(LabInfoTable);

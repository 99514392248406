import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchInput = ({
  fetchLabsData,
  selectedPlan,
  dateRange,
  selectedLeadOwner,
  selectedLeadSource,
  selectedSupportPerson,
  setSearchQueryParent,
  cancelTokenSource,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const timeOutId = setTimeout(() => {
      if (searchQuery.length <= 3) return;
      fetchLabsData(
        selectedPlan,
        dateRange,
        selectedLeadOwner,
        selectedLeadSource,
        selectedSupportPerson,
        searchQuery,
      );
      setSearchQueryParent(searchQuery);
    }, 500);
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    return () => {
      clearTimeout(timeOutId);
    };
  }, [searchQuery]);

  return (
    <Search
      allowClear={true}
      placeholder="Search by Name, Contact, City, ID"
      style={{
        width: 300,
      }}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
};

export default SearchInput;
